import { AxiosError } from 'axios';
import { PaginationParams } from '@/common/models/pagination';
import { PaginationUser, PlainUser } from '@/common/models/user';
import ApiService from '../api-service';

interface UsersResponse {
  statusCode: number;
  data: PaginationUser | undefined;
  message: string;
}

export default class ZbUsersService extends ApiService {
  async getUsers(params?: PaginationParams): Promise<UsersResponse> {
    try {
      const response = await this.axios.get<PaginationUser>(`admin/users`, { params });
      return {
        statusCode: response.status,
        data: response.data,
        message: 'success',
      };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return {
          statusCode: e.response.status,
          data: undefined,
          message: e.response.data.detail,
        };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }

  async createUser(user: PlainUser): Promise<boolean> {
    try {
      await this.axios.post<{ detail: string }>(`admin/users`, user);
      return true;
    } catch (error) {
      return false;
    }
  }

  async exportUsers(
    value: string
  ): Promise<{ statusCode: number; data: string | undefined; message: string }> {
    try {
      const response = await this.axios.get(`admin/users/export?search=${value}`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
      return { statusCode: response.status, data: response.data, message: 'Datei heruntergeladen' };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return {
          statusCode: e.response.status,
          data: undefined,
          message: e.response.data.detail,
        };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }
}
