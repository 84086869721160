









































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ZbUserService from '@/common/services/users/users-service';
import { UserInfo, PlainUser } from '@/common/models/user';
import displayMessage from '@/common/functions/display-message';
import { PaginationParams } from '@/common/models/pagination';

const defaultPlainUser: PlainUser = {
  username: '',
  institution: '',
  street: '',
  zip_code: 0,
  city: '',
  max_quantity: 0,
  email: '',
};

@Component
export default class UsersList extends Vue {
  userService: ZbUserService = new ZbUserService();

  search = '';

  isLoadingTable = true;

  isLoadingCsvButton = false;

  isLoadingCreateUser = false;

  count = 0;

  itemsPerPage = 10;

  currentPage = 1;

  newUser: PlainUser = defaultPlainUser;

  displayCreateUserDialog = false;

  $refs!: {
    createUserFormRef: HTMLFormElement;
  };

  headers = [
    {
      text: 'Einrichtung',
      value: 'profile.institution',
      sortable: true,
    },
    {
      text: 'Username',
      value: 'username',
      sortable: true,
    },
    {
      text: 'E-Mail',
      value: 'email',
      sortable: true,
    },
    {
      text: 'Adresse',
      value: 'profile.address',
      sortable: true,
    },
    {
      text: 'Aktions',
      value: 'actions',
      sortable: false,
    },
  ];

  users: UserInfo[] = [];

  mounted() {
    this.getUsers();
  }

  async getUsers(params?: PaginationParams) {
    this.isLoadingTable = true;

    const { data, message } = await this.userService.getUsers(params);
    if (data) {
      this.users = data.results;
      this.count = data.count;
    } else {
      displayMessage(message, true, this.$store);
    }

    this.isLoadingTable = false;
  }

  async exportCSV() {
    this.isLoadingCsvButton = true;

    const response = await this.userService.exportUsers(this.search.trim());
    if (response.statusCode === 200) {
      displayMessage(response.message, false, this.$store);
    } else {
      displayMessage(response.message, true, this.$store);
    }

    this.isLoadingCsvButton = false;
  }

  cleanField(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.getUsersBySearch();
    }
    if (event.key === ' ') {
      const { value } = event.target as HTMLInputElement;
      if (!value.trim() || value.endsWith(' ')) {
        event.preventDefault();
      }
    }
  }

  async getUsersBySearch() {
    const params = this.getParams();
    this.getUsers(params);
  }

  getParams(): PaginationParams {
    const params: PaginationParams = {};
    if (this.search !== '') {
      params.search = this.search;
    }
    return params;
  }

  onPageChange(page: number) {
    const params = this.getParams();
    params.page = page;
    this.getUsers(params);
  }

  get pageCount() {
    const division = this.count / this.itemsPerPage;
    return Math.ceil(division);
  }

  goToRoute(id: number, route: string) {
    if (this.$route.name !== route) {
      this.$router.push({ name: route, query: { userId: `${id}` } });
    }
  }

  async createUser(): Promise<void> {
    this.isLoadingCreateUser = true;

    const userWasCreated = await this.userService.createUser(this.newUser);
    if (userWasCreated) {
      displayMessage('Benutzer erfolgreich erstellt!', false, this.$store);
      this.displayCreateUserDialog = false;
      this.newUser = defaultPlainUser;
      this.getUsers();
    } else {
      displayMessage('Fehler! Bitte versuche es erneut.', true, this.$store);
    }

    this.isLoadingCreateUser = false;
  }

  @Watch('search')
  textFieldWithoutSpaces() {
    this.search = this.search.replace('  ', ' ').trim();
  }
}
