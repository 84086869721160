var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-4 flex"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('h1',[_vm._v("Benutzer")])]),_c('v-col',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-text-field',{attrs:{"id":"searchInput","filled":"","append-icon":"mdi-magnify"},on:{"click:append":_vm.getUsersBySearch,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getUsersBySearch.apply(null, arguments)},function($event){return _vm.cleanField($event)}]},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-5",attrs:{"dark":"","type":"submit","loading":_vm.isLoadingCsvButton,"x-large":""},on:{"click":function($event){return _vm.exportCSV()}}},[_vm._v("CSV-Export")]),_c('v-btn',{staticClass:"ml-5",attrs:{"dark":"","type":"button","loading":_vm.isLoadingCsvButton,"x-large":""},on:{"click":function($event){_vm.displayCreateUserDialog = true}}},[_vm._v("Benutzer erstellen")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.users,"items-per-page":20,"loading":_vm.isLoadingTable,"loading-text":"Laden von Artikeln","no-data-text":"keine Daten verfügbar"},scopedSlots:_vm._u([{key:"item.institution",fn:function(ref){
var item = ref.item;
return [(item.profile)?_c('span',[_vm._v(_vm._s(item.profile.institution))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.goToRoute(item.id, 'profile')}}},[_vm._v("mdi-pencil")])]}}],null,true)}),_c('v-pagination',{staticClass:"mt-5",attrs:{"color":"black","length":_vm.pageCount,"total-visible":7,"items-per-page":_vm.itemsPerPage,"total-items":_vm.count},on:{"input":function($event){return _vm.onPageChange($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.displayCreateUserDialog),callback:function ($$v) {_vm.displayCreateUserDialog=$$v},expression:"displayCreateUserDialog"}},[_c('v-card',{staticClass:"px-10 py-7"},[_c('h2',{staticClass:"font-weight-bold mb-5"},[_vm._v("Benutzer erstellen")]),_c('validation-observer',{ref:"createUserFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createUser)}}},[_c('validation-provider',{staticClass:"pt-2",attrs:{"name":"USERNAME","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Aktenzeichen / Jugendamtsnummer","filled":"","error-messages":errors,"success":valid},model:{value:(_vm.newUser.username),callback:function ($$v) {_vm.$set(_vm.newUser, "username", $$v)},expression:"newUser.username"}})]}}],null,true)}),_c('validation-provider',{staticClass:"pt-2",attrs:{"name":"INSTITUTION","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Einrichtung","filled":"","error-messages":errors,"success":valid},model:{value:(_vm.newUser.institution),callback:function ($$v) {_vm.$set(_vm.newUser, "institution", $$v)},expression:"newUser.institution"}})]}}],null,true)}),_c('validation-provider',{staticClass:"pt-2",attrs:{"name":"STREET","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Straße","filled":"","error-messages":errors,"success":valid},model:{value:(_vm.newUser.street),callback:function ($$v) {_vm.$set(_vm.newUser, "street", $$v)},expression:"newUser.street"}})]}}],null,true)}),_c('validation-provider',{staticClass:"pt-2",attrs:{"name":"ZIPCODE","rules":"required|greater_than_zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","label":"PLZ","filled":"","error-messages":errors,"success":valid},model:{value:(_vm.newUser.zip_code),callback:function ($$v) {_vm.$set(_vm.newUser, "zip_code", $$v)},expression:"newUser.zip_code"}})]}}],null,true)}),_c('validation-provider',{staticClass:"pt-2",attrs:{"name":"CITY","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Stadt","filled":"","error-messages":errors,"success":valid},model:{value:(_vm.newUser.city),callback:function ($$v) {_vm.$set(_vm.newUser, "city", $$v)},expression:"newUser.city"}})]}}],null,true)}),_c('validation-provider',{staticClass:"pt-2",attrs:{"name":"MAXQUANTITY","rules":"required|greater_than_zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","label":"Maximale Liefermenge","filled":"","error-messages":errors,"success":valid},model:{value:(_vm.newUser.max_quantity),callback:function ($$v) {_vm.$set(_vm.newUser, "max_quantity", $$v)},expression:"newUser.max_quantity"}})]}}],null,true)}),_c('validation-provider',{staticClass:"pt-2",attrs:{"name":"EMAIL","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"E-Mail","filled":"","error-messages":errors,"success":valid},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})]}}],null,true)}),_c('v-btn',{attrs:{"width":"100%","type":"submit","x-large":"","dark":!invalid,"disabled":invalid,"loading":_vm.isLoadingCreateUser}},[_vm._v("Speichern")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }